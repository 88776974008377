import React from 'react'

function Home() {
  return (
    <div className='home'>
  
      

    </div>
  )
}

export default Home