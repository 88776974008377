import React from 'react'
import './Service.css'
import ServiceMain from './ServiceMain'


function Service() {
  return (
  <>
   <div className="service">
    <h1>Service</h1>
   </div>
   <ServiceMain />

  </>
    
  )
}

export default Service